import React, {useState} from "react"
import { navigate } from "gatsby"
import { Form, FormState } from "gatsby-theme-firebase";
import { loginLink, handleLoginFirebase, isLoggedIn, logout} from "../../services/auth"

const setMessage = message => {
    console.log('message', message)
}

export default ({location}) => {
    if (isLoggedIn() && location.pathname !== loginLink) {
        logout(() => navigate(`/`))
    }
    return(
    <div className="columns is-vcentered">
        <div className="column is-12">  
            <h1>Custom Login</h1>
            <FormState.Provider>
                <Form
                    onLoginSuccess={user => {
                        handleLoginFirebase(user)  
                        navigate('/');
                    }}
                />
            </FormState.Provider>
        </div>
     </div>
    )
                    };